module.exports = [{
      plugin: require('/codebuild/output/src443553152/src/cwb-tp/node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/codebuild/output/src443553152/src/cwb-tp/src/components/app-layout/AppLayout.tsx"},
    },{
      plugin: require('/codebuild/output/src443553152/src/cwb-tp/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-65905680-1","sampleRate":5,"siteSpeedSampleRate":10},
    },{
      plugin: require('/codebuild/output/src443553152/src/cwb-tp/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
