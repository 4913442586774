const title = 'Chris Burke';
const description = `Hi I'm Chris, a Full Stack Developer & Designer with over 15 years of digital agency experience.`;

const favicon = require('assets/images/favicon.png');

export const helmet = {
  title,
  titleTemplate: 'Chris W. Burke',
  htmlAttributes: { lang: 'en' },
  meta: [
    { name: 'description', content: description },
    { name: 'viewport', content: 'width=device-width, initial-scale=1, user-scalable=no' },
    { name: 'apple-mobile-web-app-status-bar-style', content: 'black' },
    { name: 'msapplication-navbutton-color', content: '#000' },
    { name: 'msapplication-TileColor', content: '#000' },
    { name: 'theme-color', content: '#000' },

    { property: 'og:title', content: title },
    { property: 'og:image:width', content: '880px' },
    { property: 'og:image:height', content: '440px' },
    { property: 'og:image:alt', content: description },

    { name: 'twitter:title', content: title },
    { name: 'twitter:site', content: '@chriswburke' },
    { name: 'twitter:creator', content: '@chriswburke' },
    { name: 'twitter:description', content: description },
  ],
  link: [
    { rel: 'icon', type: 'image/x-icon', href: favicon },
  ],
};
